module.exports = {
	OPTIONAL: '可选填',
	EMAILS: '邮箱管理',
	EMAIL: '邮箱',
	DATE: '日期',
	AMOUNT: '总计',
	INVOICE: '发票',
	SECURE: '安全',

	profile: {
		PROFILE: '个人信息',
		PROFILE_INFO: '详情',
		DISPLAY_NAME: '昵称',
		FULL_NAME: '全名',
		BIRTH_DATE: '出生日期',
		ADD_EMAIL: '添加邮箱',
		ADD_EMAIL_PROMPT: '请输入你想添加的邮箱地址',
		MAKE_PRIMARY: '设置为主邮箱',
		PASSWORD: '密码',
		CHANGE_PASSWORD: '修改密码',
		CHOOSE_NEW_PASSWORD: '选择一个新密码',
		DELETE_ACCOUNT: '删除账号',
		DELETE_WARNING: '警告: 您账号现有的所有数据将会被永久删除。',
		AUTO_RENEW_WARNING: '请先撤销自动续费功能',
		AUTO_RENEW_WARNING_DESCRIPTION: '我们无法在您的账号处于自动续费模式时删除您的账号。',
		DELETE_TITLE: '想要删除您的账号吗?',
		DELETE_CONFIRM_TITLE: '你确定想要永久删除你的账号吗?',
		YES_PROCEED: '是的，删除',
		NO_DELETE: '再考虑一下',
	},

	status: {
		STATUS: '状态',
		UNVERIFIED: '未经认证',
		PRIMARY: '主邮箱',
		REDEEMED: '已兑现',
		AVAILABLE: '可用',
		PURCHASED: '已购',
	},

	upgrade: {
		UPGRADE: '升级账号',
		UPGRADE_TITLE: '升级为{partnerName} Plus',
		UPGRADE_DESCRIPTION: '为你的新标签页添加活力',
		ACCOUNT_INFO: '账号信息',
		ENTER_YOUR_EMAIL: '请输入您的邮箱',
		CONFIRM_YOUR_EMAIL: '请确认您的邮箱',
		CHOOSE_PLAN: '选择服务',
		CHOOSE_A_PLAN: '请选择一项服务',
		JOIN_PLUS: '加入我们40,000 Plus用户的行列.',
		PAYMENT_DETAILS: '支付信息',
		ENTER_PAYMENT_DETAILS: '请输入您的支付信息',
		CARD_DETAILS: '支付卡信息',
		NAME_ON_CARD: '持卡人名字',
		COUNTRY: '国家',
		UPGRADE_DIFFERENT_ACCOUNT_PROMPT: '想要升级另一个账户？ ',
		SWITCH_ACCOUNT: '切换账户',
		PAYMENT_THANKS:
			'非常感谢您选择升级成{partnerPlusPlan}，所有Plus的功能将会在您升级后自动生效。您可以在任何时候选择升级或取消您的Plus。所有标注价格为美元。',
		PAYMENT_THANKS_WITH_TRIAL:
			'非常感谢您选择升级成{partnerPlusPlan}，所有Plus的功能将会在您升级后自动生效。我们在您的30天试用期到后扣取款项，在那期间您可以任何时候取消升级。所有标注价格为美元。',
		PAYMENT_THANKS_TEAM:
			'非常感谢您选择升级成{partnerTeamPlan}，所有Team的功能将会在您升级后自动生效。所有标注价格为美元。',
	},

	thanks: {
		HEADER: '非常感谢您订阅{partnerPlusPlan} Plus!',
		PLEASE_SET_PASSWORD: '请为您的新PLUS账号设置密码。',
		ENCOUNTER_PROBLEMS: '若您有任何问题, 敬请',
		NEW_FEATURES_WILL_APPEAR: '您新订阅的功能马上就会显示在您的账号上。',
		GET_STARTED: '打开您的{partnerName}界面来尝试您获得的新功能！',
		EXTENSION_NOT_INSTALLED: '您还没有安装{partnerName}，',
		INSTALL_AND_LOG_IN: '请安装{partnerName}，然后登录后您就可以享受所有的Plus功能了。',
		BROWSER_UNSUPPORTED: '{partnerName}暂时无法在您所用的浏览器上使用。',
		CURRENTLY_AVAILABLE_FOR: '现在{partnerName}在以下浏览器上可用：',
		GET_BROWSER: '下载{browser}',
	},

	subscription: {
		SUBSCRIPTION: '账号管理',
		PLAN: '服务',
		PENDING_CANCELLATION: '您已取消自动续费功能。 您当前的服务期限至 ',
		TURN_ON_AUTO_RENEW: '开启自动续费功能',
		TURN_OFF_AUTO_RENEW: '关闭自动续费功能',
		NEXT_RENEWAL: '下次续费日期',
		ACCOUNT_CREDIT: '账号积分',
		ACCOUNT_CREDIT_HELP: '在付费时，我们将会默认先从您的积分中扣除',
		ACCOUNT_BALANCE: '账号余额',
		AMOUNTS_UNDER_ONE: '由于交易手续费的原因，任何在$1以下的款项我们都会在您下一次续费时扣除。',
		PAYMENT_METHOD: '付款方式',
		ADD_PAYMENT_METHOD: '添加付款方式',
		ADD_NEW_CARD: '添加支付卡信息',
		NO_PAYMENT_METHOD: '暂未提供付款方式与信息。',
		MAKE_PRIMARY: '设为主卡',
		STATUS_PRIMARY: '主卡',
		METHOD: '卡号信息',
		ENDING_IN: '卡尾号 {last4}',
		EXPIRES: '有效期至',
		HISTORY: '付费历史',
		NO_PAYMENT_CHARGED: '您的账号还未被扣取任何款项。',
	},

	gifts: {
		GIFTS: '礼品赠送',
		GIFT_CODES: '礼品验证码',
		GET_A_CODE: '购买礼品',
		REDEEM: '兑现',
		NO_GIFTS_YET: '你还未赠送过{partnerName}作为礼物哦！',
		GIFT: '赠送{partnerName}',
		GIFT_TYPE: '类型',
		GIFT_CODE: '验证码',
	},

	metricCycles: {
		NONE: '不重置',
		DAILY: '每日',
		WEEKLY: '每周',
		MONTHLY: '每月',
		YEARLY: '每年',
	},

	copy: {
		LOADING: '加载中…',
		LOG_IN: '登入',
		LOGGING_IN: '登入中…',
		LOG_OUT: '登出',
		ONETIME_LOGIN_BUTTON_LABEL_INITIAL: '发送账号恢复链接',
		ONETIME_LOGIN_BUTTON_LABEL_SUCCESS: '发送完毕!',
		ONETIME_LOGIN_STARTED: '发送完毕! 请进入你的邮箱并点击我们发给你的链接来登入.',
		PROCESSING: '读取中…',
		RESEND: '重新发送',
		RESEND_LINK: '重发链接',
		RESET_PASSWORD_BUTTON_LABEL_INITIAL: '设置密码',
		RESET_PASSWORD_BUTTON_LABEL_SUCCESS: '修改成功',
		RESET_PASSWORD_STARTED: '请登入你的邮箱并确认以修改的密码。如果邮件暂未发来，请耐心等待。',
		SENDING: '发送中…',
		LEARN_MORE: '更多',
		HELP: '帮助',
		SETTING: '设置中…',
		GO_TO_DASHBOARD: '打开标签页程序',
		INSTALL_DASHBOARD: '下载{partnerName}',
		SAVE: '保存',
		SAVING: '保存中…',
		SAVED: '已保存!',
		ADD: '添加',
		CANCEL: '取消',
		REMOVE: '移除',
		NEXT: '下一步',
		DELETE: '删除',
		DELETING: '删除中…',
		CONTACT: '联系方式',
		CONTACT_US: '联系我们',
		LAUNCHING: '正在打开…',
		LAUNCH_DASHBOARD: '打开',
	},
}

import Vue from 'vue'
import md5 from 'md5'
import router from '../router'
import extensionManager from '../managers/extensionManager'
import util from '../util'

function initialData() {
	return {
		uuid: null,
		display_name: null,
		full_name: null,
		birth_day: null,
		birth_month: null,
		birth_year: null,
		isAdmin: null,
		isTeamMember: null,
		isCommunityMember: null,
		background: require('../img/bg/1.jpg'),
		email: null,
		emailHash: null,
		emails: [],
		img: '',
		subscriptionSummary: {
			active: null,
			error: null,
			hasPaymentMethod: null,
			pastDue: null,
			subscriptionEnd: null,
			trialEnd: null,
			trialActive: null,
			intent: null,
		},
		createdDate: null,

		refreshing: false,
		saving: false,
		loaded: false,

		error: false,
		addEmailError: false,
		saveError: false,
		paymentRequired: false,

		partnerId: 0,
	}
}

export default Vue.extend({
	data: initialData,
	computed: {
		name() {
			return this.full_name || this.display_name
		},
		friendlyName() {
			const spaceIndex = this.full_name ? this.full_name.indexOf(' ') : -1
			return this.display_name || (spaceIndex > 0 ? this.full_name.substring(0, spaceIndex) : this.full_name)
		},
		subscriptionDaysRemaining() {
			if (this.subscriptionSummary.subscriptionEnd) {
				return util.daysRemaining(this.subscriptionSummary.subscriptionEnd)
			}
		},
		activeSubscription() {
			return this.subscriptionSummary.active
		},
		isTeamAdmin() {
			return this.isAdmin && this.isTeamMember
		},
		isCommunityAdmin() {
			return this.isAdmin && this.isCommunityMember
		},
		birthdate() {
			return `${this.birth_year}-${this.birth_month}-${this.birth_day}`
		},
	},

	created() {
		this.updateDataFromCache()
	},
	methods: {
		props() {
			return (({ display_name, full_name, birth_day, birth_month, birth_year }) => ({
				display_name,
				full_name,
				birth_day,
				birth_month,
				birth_year,
			}))(this)
		},
		update(response) {
			localStorage.removeItem('hard-delete')
			this.display_name = response.display_name
			this.background = response.background
			this.full_name = response.full_name
			let options = { year: 'numeric', month: 'short', day: 'numeric' }
			this.createdDate = new Date(response.created).toLocaleString('en-us', options)
			this.email = response.default_email || response.email
			this.emails = response.emails || this.emails
			this.emails.forEach(email => {
				this.$set(email, 'error', null)
			})
			this.uuid = response.user_uuid

			this.isAdmin = response.userIsAdmin
			this.isTeamMember = response.userIsTeamUser
			this.isCommunityMember = response.userIsCommunityUser
			if (this.email) {
				this.emailHash = md5(this.email)
				this.img = this.getImg(this.emailHash)
			}
			this.paymentRequired = !response.active_team
			this.partnerId = response.partnerId
			if (response.birth_day) {
				this.birth_day = response.birth_day
				this.birth_month = response.birth_month
				this.birth_year = response.birth_year
			}
			if (response.subscriptionSummary) {
				this.subscriptionSummary.active = response.subscriptionSummary.active
				this.subscriptionSummary.error = response.subscriptionSummary.error
				this.subscriptionSummary.hasPaymentMethod = response.subscriptionSummary.hasPaymentMethod
				this.subscriptionSummary.pastDue = response.subscriptionSummary.pastDue
				this.subscriptionSummary.subscriptionEnd = response.subscriptionSummary.subscriptionEnd
				this.subscriptionSummary.trialEnd = response.subscriptionSummary.trialEnd
				this.subscriptionSummary.trialActive = response.subscriptionSummary.trialing
				this.subscriptionSummary.intent = response.subscriptionSummary.intent
			}
			this.loaded = true
			localStorage.setObject('userInfo', response)
			this.redirectIfAdminStatusChanged()
			let tries = 0
			const userUuid = this.uuid
			function setLoginState() {
				tries++
				if (!extensionManager.checkIfExtensionIsInstalled()) {
					if (tries < 10) setTimeout(setLoginState, 100)
					return
				}
				window.postMessage(
					{
						type: 'momentum:authState',
						data: { src: 'account', loginState: true, userId: userUuid },
					},
					window.location
				)
			}
			setLoginState()
		},
		updateBillingStatus() {
			this.paymentRequired = false
			let cached = localStorage.getItem('userInfo')
			cached = JSON.parse(cached)
			cached.active_team = true
			localStorage.setItem('userInfo', JSON.stringify(cached))
		},
		getImg(emailHash) {
			return 'https://www.gravatar.com/avatar/' + emailHash + '?s=64&d=mm'
		},
		resetUserData() {
			let initialUserData = initialData()
			Object.keys(initialUserData).forEach(key => this.$set(this, key, initialUserData[key]))
		},
		redirectIfAdminStatusChanged() {
			// If cached user data is stale, the user may be allowed through the router gate. This double checks once the server data has come through
			if (!this.isAdmin && router.currentRoute.path.indexOf('team') > -1) router.push('/profile')
		},
		updateDataFromCache() {
			let cached = localStorage.getObject('userInfo')
			if (cached) this.update(cached)
		},
	},
})

module.exports = {
	OPTIONAL: 'Optional',
	EMAILS: 'Emails',
	EMAIL: 'Email',
	DATE: 'Date',
	AMOUNT: 'Amount',
	INVOICE: 'Invoice',
	SECURE: 'Secure',

	// Profile
	profile: {
		PROFILE: 'Profile',
		PROFILE_INFO: 'Profile Info',
		DISPLAY_NAME: 'Display Name',
		FULL_NAME: 'Full Name',
		BIRTH_DATE: 'Birthdate',
		ADD_EMAIL: 'Add Email',
		ADD_EMAIL_PROMPT: 'What email address would you like to add?',
		MAKE_PRIMARY: 'Make Primary',
		PASSWORD: 'Password',
		CHANGE_PASSWORD: 'Change Password',
		CHOOSE_NEW_PASSWORD: 'Choose a new password',
		DELETE_ACCOUNT: 'Delete Account',
		DELETE_WARNING: 'Warning: Your account and all of its data will be permanently deleted.',
		AUTO_RENEW_WARNING: 'Please turn off auto-renew first',
		AUTO_RENEW_WARNING_DESCRIPTION: 'We cannot delete your account when your subscription is set to auto-renew.',
		DELETE_TITLE: 'Delete your {partnerName} account?',
		DELETE_CONFIRM_TITLE: 'Are you sure you want to delete your account?',
		YES_PROCEED: 'Yes, proceed',
		NO_DELETE: 'No, I’ve changed my mind',
	},

	status: {
		STATUS: 'Status',
		UNVERIFIED: 'Unverified',
		PRIMARY: 'Primary',
		REDEEMED: 'Redeemed',
		AVAILABLE: 'Available',
		PURCHASED: 'Purchased',
	},

	upgrade: {
		UPGRADE: 'Upgrade',
		UPGRADE_TITLE: 'Upgrade to {partnerPlusPlan}',
		UPGRADE_DESCRIPTION: 'Power up your dashboard',
		ACCOUNT_INFO: 'Account Info',
		ENTER_YOUR_EMAIL: 'Enter your email',
		CONFIRM_YOUR_EMAIL: 'Confirm your email',
		CHOOSE_PLAN: 'Choose Plan',
		CHOOSE_A_PLAN: 'Choose a plan',
		JOIN_PLUS: 'Join 50,000+ people who’ve upgraded to Plus!',
		PAYMENT_DETAILS: 'Payment Details',
		ENTER_PAYMENT_DETAILS: 'Enter your payment details',
		CARD_DETAILS: 'Card Details',
		NAME_ON_CARD: 'Name on Card',
		COUNTRY: 'Country',
		UPGRADE_DIFFERENT_ACCOUNT_PROMPT: 'Want to upgrade a different account? ',
		SWITCH_ACCOUNT: 'Switch account',
		PAYMENT_THANKS:
			'Thanks for choosing {partnerPlusPlan}. Upon upgrading, you will get immediate access to all features. You can upgrade or downgrade at any time. All prices in US dollars.',
		PAYMENT_THANKS_WITH_TRIAL:
			"Thank you for choosing {partnerPlusPlan}. Upon upgrading, all features will be available immediately in your account. Your card won't be charged until the end of your 30-day trial. You can cancel your trial at any time and you won't be charged. All prices in US dollars.",
		PAYMENT_THANKS_TEAM:
			'Thank you for choosing {partnerTeamPlan}. Upon upgrading, Team features will be available immediately for you and your team members. All prices in US dollars.',
		GUARANTEE_TITLE: 'Upgrade with confidence',
		GUARANTEE_DESCRIPTION: '30-day money-back guarantee!',
	},

	thanks: {
		HEADER: 'Thanks for upgrading to {partnerPlusPlan}!',
		PLEASE_SET_PASSWORD: 'Please set a password for your account.',
		ENCOUNTER_PROBLEMS: 'If you encounter any problems, please ',
		NEW_FEATURES_WILL_APPEAR: 'Your new features will now appear in your {partnerName} account.',
		GET_STARTED: 'Launch {partnerName} to try them out!',
		EXTENSION_NOT_INSTALLED: 'It looks like you haven’t installed {partnerName} on your browser yet.',
		INSTALL_AND_LOG_IN: 'To access your new features, install {partnerName} and then log into your account.',
		BROWSER_UNSUPPORTED: 'It looks like you are using a browser that {partnerName} doesn’t support.',
		CURRENTLY_AVAILABLE_FOR: 'The {partnerName} extension is currently available for ',
		GET_BROWSER: 'Get {browser}',
	},

	subscription: {
		SUBSCRIPTION: 'Subscription',
		PLAN: 'Plan',
		PENDING_CANCELLATION: 'You’ve turned off auto-renew. Your current plan will remain active until ',
		TURN_ON_AUTO_RENEW: 'Turn on auto-renew',
		TURN_OFF_AUTO_RENEW: 'Turn off auto-renew',
		NEXT_RENEWAL: 'Next renewal',
		FIRST_CHARGE: 'First charge',
		ACCOUNT_CREDIT: 'Account Credit',
		ACCOUNT_CREDIT_HELP: 'Any billing will use account credit first.',
		ACCOUNT_BALANCE: 'Account Balance',
		AMOUNTS_UNDER_ONE:
			'Some amounts under $1 will be charged on the next renewal instead, due to transaction fees.',
		PAYMENT_METHOD: 'Payment Method',
		ADD_PAYMENT_METHOD: 'Add Payment Method',
		ADD_NEW_CARD: 'Add New Card',
		NO_PAYMENT_METHOD: 'No payment method entered.',
		MAKE_PRIMARY: 'Make Primary',
		STATUS_PRIMARY: 'Primary',
		METHOD: 'Method',
		ENDING_IN: 'ending in {last4}',
		EXPIRES: 'Expires',
		HISTORY: 'History',
		NO_PAYMENT_CHARGED: 'No payments have been charged to your account.',
	},

	gifts: {
		GIFTS: 'Gifts',
		GIFT_CODES: 'Gift Codes',
		GET_A_CODE: 'Get a code',
		REDEEM: 'Redeem',
		NO_GIFTS_YET: "You haven't given the gift of {partnerName} yet.",
		GIFT: 'Gift {partnerName}',
		GIFT_TYPE: 'Gift Type',
		GIFT_CODE: 'Gift Code',
	},

	metricCycles: { NONE: 'None', DAILY: 'Daily', WEEKLY: 'Weekly', MONTHLY: 'Monthly', YEARLY: 'Yearly' },

	// Commonly used pieces of copy.
	copy: {
		LOADING: 'Loading…', // Use for general data-related actions
		LOG_IN: 'Log in',
		LOGGING_IN: 'Logging in…',
		LOG_OUT: 'Log out',
		ONETIME_LOGIN_BUTTON_LABEL_INITIAL: 'Send recovery link',
		ONETIME_LOGIN_BUTTON_LABEL_SUCCESS: 'Sent!',
		ONETIME_LOGIN_STARTED: 'Sent! Check your email and click the link to log in.',
		PROCESSING: 'Processing…', // Use only for payment processing
		RESEND: 'Re-send',
		RESEND_LINK: 'Re-send Link',
		RESET_PASSWORD_BUTTON_LABEL_INITIAL: 'Set password',
		RESET_PASSWORD_BUTTON_LABEL_SUCCESS: 'Change succeeded',
		RESET_PASSWORD_STARTED:
			'Check your email to confirm your password change. This may take a few moments to arrive.',
		SENDING: 'Sending…',
		LEARN_MORE: 'Learn more',
		HELP: 'Help',
		SETTING: 'Setting…',
		GO_TO_DASHBOARD: 'Go to Dashboard',
		INSTALL_DASHBOARD: 'Install {partnerName}',
		SAVE: 'Save',
		SAVING: 'Saving…',
		SAVED: 'Saved!',
		ADD: 'Add',
		CANCEL: 'Cancel',
		REMOVE: 'Remove',
		NEXT: 'Next',
		DELETE: 'Delete',
		DELETING: 'Deleting…',
		CONTACT: 'Contact',
		CONTACT_US: 'Contact Us',
		LAUNCHING: 'Launching…',
		LAUNCH_DASHBOARD: 'Launch Dashboard',
		CONFIRM_SIGN_IN_BUTTON_LABEL: 'Confirm sign in',
		CONFIRM_SIGN_IN: 'Please confirm your one-time sign in.',
	},
}

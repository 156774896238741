import Vue from 'vue'
import './initialize'
import Router from 'vue-router'
import VueStash from 'vue-stash'
import Axios from 'axios'
import util from './util'
import directives from './directives'
import store from './store'
import router from '@/router'
import authManager from '@/managers/authManager'
import App from './App'
import CurrentUserModel from './models/CurrentUserModel'
import TeamsInfoModel from './models/TeamsInfoModel'
import AuthInfoModel from './models/AuthInfoModel'
import i18n from '@/i18n'
import language from '@/language'
import SubscriptionInfoModel from './models/SubscriptionInfoModel'
import GiftInfoModel from './models/GiftInfoModel'
const ExtUpgrade = () => import('./components/subscription/ExtUpgrade/ExtUpgrade')
const ExtUpgradeV2 = () => import('./components/subscription/ExtUpgrade/ExtUpgradeV2')

// Extending JS methods
Element.prototype.remove = function () {
	this.parentElement && this.parentElement.removeChild(this)
}

NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
	for (let i = this.length - 1; i >= 0; i--) {
		if (this[i] && this[i].parentElement) {
			this[i].parentElement.removeChild(this[i])
		}
	}
}

const axios = Axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URI,
	timeout: 100000,
})

axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		if (!config) config = {}
		config.headers = {
			...config.headers,
			...(store.userInfo.partnerId ? { 'X-Momentum-PartnerId': store.userInfo.partnerId } : {}),
		}
		const whiteListed = urlIsWhiteListed(config.url, config.baseURL),
			greyListed = urlIsGreyListed(config.url, config.baseURL)
		if (!whiteListed) {
			config.withCredentials = false
			let token = window.localStorage.getItem('token')
			if (token) {
				config.headers.Authorization = 'Bearer ' + token
				config.headers['X-Momentum-Clientdate'] = util.activeLocalDateTimeStringForDate(new Date())
			} else if (!greyListed) {
				if (util.isDev()) {
					console.log('WARNING - Url is not anonymous and you are not logged in - whitelist this if needed.')
				}
				return {
					...config,
					cancelToken: new Axios.CancelToken(cancel => cancel('Cancel unauthorized request')),
				}
			}
		}
		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	}
)

Vue.prototype.$http = window.axiosMain = axios

Vue.use(Router)
Vue.use(VueStash)

directives()

function urlIsWhiteListed(url, baseUrl) {
	// SECURITY NOTE: we need to ensure that we whitelist all non-momentum sites, so we don't send our token to other services
	// TODO: Unit test the shit out of this!!
	if (url.startsWith(baseUrl) || (!url.startsWith('https://') && !url.startsWith('http://'))) {
		const whiteList = [
			'user/authenticate',
			'user/register',
			'user-search',
			'login/sso',
			'user/verify-invite',
			'user/accept-invite',
			'user/resend-invite',
			'user/forgot',
			'user/password',
			'login/onetime/send',
			'login/onetime/redeem',
			'account/plans',
			'coupons',
			'team/basicInfo',
		]
		if (url.startsWith(baseUrl)) url = url.replace(baseUrl, '')
		if (url.startsWith('/')) url = url.slice(1)
		return whiteList.some(whiteListedUrl => {
			return url.startsWith(whiteListedUrl)
		})
	}
	return true
}

function urlIsGreyListed(url, baseUrl) {
	// Grey listed urls are allowed to be requested without a token, but a token will be sent if it exists
	// TODO: DRY these up
	if (url.startsWith(baseUrl) || (!url.startsWith('https://') && !url.startsWith('http://'))) {
		const greyList = ['team/onboarding', 'account/subscriptions']
		if (url.startsWith(baseUrl)) url = url.replace(baseUrl, '')
		if (url.startsWith('/')) url = url.slice(1)
		return greyList.some(greyListedUrl => {
			return url.startsWith(greyListedUrl)
		})
	}
	return true
}

initializeStore()
authManager.initialize()

// Moves the ExtUpgrade components outside of the app environment (removes sidebar, styles, etc.)
const rootComponent = (() => {
	const pathname = window.location.pathname
	if (pathname === '/ext-upgrade') return ExtUpgrade
	if (pathname === '/ext-upgrade-v2') return ExtUpgradeV2
	return App
})()
const options = {
	i18n,
	router: router,
	data: { store },
	render: h => h(rootComponent),
}
// Captures component errors for in extension upgrade and sends then to the extension for handling
if (rootComponent !== App) {
	function postError(error, vm, info) {
		const extensionUrl = new URLSearchParams(window.location.search).get('parentURL')
		window.parent.postMessage(
			{ type: 'upgrade:error', message: error.message, component: vm?.$options?.name, info },
			extensionUrl
		)
	}
	window.addEventListener('error', postError)
	options.errorCaptured = postError
}
const app = new Vue(options)

app.$mount('#app')

// check if localstorage has 'language' setting, if yes set the locale to this lang or else get the browser language
let localLanguage = localStorage.getItem('language')
if (localLanguage && localLanguage !== i18n.locale) {
	if (language.supportedLanguages.includes(localLanguage)) {
		i18n.locale = localLanguage
	} else {
		i18n.locale = i18n.fallbackLocale
		localStorage.setItem('language', i18n.fallbackLocale)
	}
} else if (!localLanguage) {
	language.loadLanguageAsync(navigator.languages).catch(error => {
		i18n.locale = i18n.fallbackLocale
		console.log(error)
	})
}

if (util.loadDevPanel() && rootComponent === App) {
	Vue.config.devtools = true
	import('@/dev/devMain.js').then(dev => {
		window.$store = store
		dev.default.setupOverlay(router)
	})
}

if (window.Cypress) {
	// only available during E2E tests
	window.$store = store
}
// Detect browser type
let userAgent = navigator.userAgent
const isChromium = !!window.chrome

const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || userAgent.includes(' OPR/')
const isEdge = isChromium && userAgent.includes(' Edg/')
const isChrome = isChromium && !isOpera && !isEdge
const isFirefox = userAgent.toLowerCase().includes('firefox')
const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome')
// https://stackoverflow.com/a/29696509/10078432
const isSafariIos =
	(!!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i)) &&
	!!userAgent.match(/WebKit/i) &&
	!userAgent.match(/CriOS/i)
// https://stackoverflow.com/a/11381730/10078432
const isMobile = (window.mobileAndTabletCheck = function () {
	let check = false
	;(function (a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		) {
			check = true
		}
	})(navigator.userAgent || navigator.vendor || window.opera)
	return check
})

Vue.set(store.browser, 'isEdge', isEdge)
Vue.set(store.browser, 'isChrome', isChrome)
Vue.set(store.browser, 'isFirefox', isFirefox)
Vue.set(store.browser, 'isSafari', isSafari)
Vue.set(store.browser, 'isSafariIos', isSafariIos)
Vue.set(store.browser, 'isMobile', isMobile())
if (isFirefox) document.body.classList.add('Firefox')
else if (isEdge) document.body.classList.add('Edge')

function initializeStore() {
	// avoid circular import
	store.userInfo = new CurrentUserModel()
	store.teamInfo = new TeamsInfoModel()
	store.authInfo = new AuthInfoModel()
	store.subscriptionInfo = new SubscriptionInfoModel()
	store.giftInfo = new GiftInfoModel()
}

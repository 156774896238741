import Vue from 'vue'
import { errorMessages, partners } from '@/constants'
import util from '@/util'
import currentUserDataService from '@/services/currentUserDataService'
import store from '@/store'

export default new (Vue.extend({
	data() {
		return {
			extensionVersion: null,
			extensionIsInstalled: false,
		}
	},
	created() {
		this.checkIfExtensionIsInstalled()
	},
	methods: {
		checkIfExtensionIsInstalled() {
			let version = document.getElementById('momentumVersion')
			this.extensionIsInstalled = !!version
			this.extensionVersion = version && version.value
			return this.extensionVersion
		},
		extensionPlatform() {
			if (!this.checkIfExtensionIsInstalled()) return null
			let momentumPlatform = document.getElementById('momentumPlatform')
			return momentumPlatform ? momentumPlatform.value : null
		},
		checkExtensionUser() {
			return new Promise(resolve => {
				function onMessage(event) {
					// We only accept messages from ourselves
					if (event.source !== window) return
					if (event.data.type && event.data.type === 'momentum:callback:checkUserId') {
						const payload = event.data?.payload
						resolve(payload)
						window.removeEventListener('message', onMessage)
					}
				}
				window.addEventListener('message', onMessage)
				window.postMessage(
					{
						type: 'momentum:checkUserId',
						payload: { userUuid: store.userInfo.uuid },
					},
					window.location
				)
			})
		},
		async launchPartnerDashboardAsCurrentUser() {
			const partnerData = partners[store.userInfo.partnerId]
			if (!partnerData.originPWA) return
			const { data } = await currentUserDataService.oneTimeLogin('account', 'account')
			const queryParams = new URLSearchParams(data)
			window.open(partnerData.originPWA + '?' + queryParams.toString(), '_blank')
		},
		async launchExtensionAsCurrentUser() {
			// If user is the same or no user is logged in on this site, this will just open a new tab
			// Otherwise it will one time log in the current user
			const loggedIn = store.authInfo.loggedIn
			const { isSameAccount } = (loggedIn && (await this.checkExtensionUser())) || {}
			const oneTimeLoginRequired = isSameAccount || !loggedIn
			return new Promise((resolve, reject) => {
				function onMessage(event) {
					// We only accept messages from ourselves
					if (event.source !== window) return
					if (
						event.data.type ===
						(oneTimeLoginRequired ? 'momentum:callback:oneTimeLogin' : 'momentum:callback:openNew')
					) {
						if (event.data.payload.success) {
							resolve(event.data.payload)
						} else {
							if (util.isDev()) {
								console.log('Check if account site api environment matches with the extension.')
							}
							reject(event.data.payload)
						}
					}
				}
				window.addEventListener('message', onMessage)

				if (oneTimeLoginRequired) {
					window.postMessage(
						{
							type: 'momentum:openNew',
						},
						origin
					)
				} else {
					let medium = this.extensionPlatform()
					if (medium === null) {
						reject(`Can't get medium: extension version not found.`)
						return
					}
					currentUserDataService
						.oneTimeLogin(medium, medium)
						.then(response => {
							if (response.status === 200 && response.data) {
								let data = response.data
								let origin = window.location
								window.postMessage(
									{
										type: 'momentum:oneTimeLogin',
										openNewTab: true,
										payload: {
											otpUuid: data.otpUuid,
											otp: data.otp,
											email: data.email,
										},
									},
									origin
								)
							} else {
								reject(errorMessages.EXTENSION_LOGIN_FAIL)
							}
						})
						.catch(err => {
							reject('Error getting onetime: ' + err)
						})
				}
			})
		},
	},
}))()

<template>
	<full-modal :modal-width="520" @close="close()">
		<smooth-height :hide-overflow="true">
			<div class="expired-modal" data-test="expired-modal">
				<div v-if="(pastDue || subscriptionExpired) && !chargeSucceeded">

					<div v-if="!subscriptionInfo.loaded" class="loading">
						<route-loader />
					</div>

					<div v-else-if="pastDue">
						<h2>Past Payment Failed</h2>
						<div>
							<p>Your last invoice of <strong>{{ lastChargeAmount }}</strong> failed. <span :class="{ retrying: submittingRetry }" class="retry" data-test="retry" @click="retryPrimaryPaymentMethod">{{ retryButtonText }}</span></p>
							<h3>Add New Card</h3>
							<payment-methods
								:refresh-subscription-after-add="true"
								:show-cancel="false"
								:show-retry-payment="true"
								:charge-card-immediately="true"
								:external-error-message="errorMessage"
								add-button-text="Charge New Card"
								class="u--no-margin-bottom"
								@submitting="onSubmit"
								@success="onSuccess"
								@end-submit="onEndSubmit"
							/>
						</div>
					</div>

					<div v-else-if="subscriptionExpired">
						<h2>{{ copy.modalHeader }}</h2>
						<p>{{ nextInvoiceMessage }}</p>
						<payment-form
							v-if="formActive"
							:on-token="renewSubscriptionWithNewCard"
							:recaptcha="true"
							@set-error="setError"
							@clear-error="clearError"
							@end-submit-process="onEndSubmit"
						/>
						<form-message v-if="errorMessage" :error="!!errorMessage" :message="errorMessage" />
						<div class="button-row">
							<button class="button" data-test="cta" @click="onButtonClick">{{ renewButtonText }}</button>
							<button v-if="!formActive" class="button button-cancel" data-test="alt-cta" @click="showForm()">Renew with new card</button>
						</div>
					</div>
				</div>

				<div v-else-if="chargeSucceeded" class="thanks" data-test="thanks">
					<h2>Payment Successful!</h2>
					<img class="icon icon-check" src="@/img/icons/checked-circle.svg" />
					<p>{{ thanksMessage }}</p>
					<button class="button" data-test="close" @click="close">Close</button>
				</div>

				<div v-else>
					<h2>{{ copy.title }}</h2>
					<p>{{ copy.message }}</p>
					<div class="button-row">
						<button class="button" data-test="cta" @click="onButtonClick">{{ copy.buttonText }}</button>
						<button class="button button-cancel" data-test="close" @click="close">Close</button>
					</div>
				</div>
			</div>
		</smooth-height>
	</full-modal>
</template>



<script>
import FullModal from '../common/FullModal'
import PaymentForm from './PaymentForm'
import PaymentMethods from './PaymentMethods'
import { copy, errorMessages, partnerExpiredCases, personalExpiredCases, teamExpiredCases } from '../../constants'
import eventBus from '../../eventBus'
import SmoothHeight from '../common/SmoothHeight'
import subscriptionManager from '../../managers/subscriptionManager'
import currentUserManager from '../../managers/currentUserManager'
import RouteLoader from '../loaders/LoadingRoutes'
import util from '../../util'
import FormMessage from '../common/FormMessage'

export default {
	name: 'ExpiredModal',
	components: {
		FormMessage,
		RouteLoader,
		SmoothHeight,
		PaymentMethods,
		PaymentForm,
		FullModal,
	},
	store: ['userInfo', 'teamInfo', 'subscriptionInfo'],
	data() {
		return {
			chargeSucceeded: false,
			formActive: false,
			submittingCard: false,
			submittingRetry: false,
			submittingRenewal: false,
			errorMessage: '',
		}
	},
	computed: {
		isTeam() {
			return this.userInfo.isAdmin
		},
		team() {
			return this.teamInfo.activeTeam
		},
		subscriptionSummary() {
			// Team or personal
			return this.isTeam ? this.team.subscriptionSummary : this.userInfo.subscriptionSummary
		},
		subscriptionExpired() {
			return this.isTeam ? this.team.subscriptionExpired : !this.subscriptionSummary.active
		},
		pastDue() {
			return this.isTeam ? this.team.pastDue : this.subscriptionSummary.pastDue
		},
		copy() {
			if (this.isTeam) {
				if (this.team.trialExpired) return teamExpiredCases.TRIAL_EXPIRED
				if (this.team.subscriptionExpired) return teamExpiredCases.SUBSCRIPTION_EXPIRED
			} else {
				if (this.pastDue) {
					if (this.userInfo.partnerId) return partnerExpiredCases.PAST_DUE
					return personalExpiredCases.PAST_DUE
				}
				if (!this.subscriptionSummary.active) {
					if (this.userInfo.partnerId) return partnerExpiredCases.SUBSCRIPTION_EXPIRED
					return personalExpiredCases.SUBSCRIPTION_EXPIRED
				}
			}
			return {}
		},
		charges() {
			return this.subscriptionInfo && this.subscriptionInfo.charges
		},
		lastChargeAmount() {
			return this.charges && this.charges[0] && this.charges[0].amount
		},
		retryButtonText() {
			return this.submittingRetry ? copy.LOADING : 'Retry Payment'
		},
		renewButtonText() {
			return this.submittingRenewal ? copy.LOADING : this.copy.shortButtonText
		},
		totalUserCount() {
			return this.lastSubscription && this.lastSubscription.quantity
		},
		thanksMessage() {
			return this.isTeam
				? 'Thanks for updating your payment method. You’re all set to continue using Momentum Team!'
				: 'Thanks for updating your payment method. You’re all set to continue using Momentum Plus!'
		},
		lastSubscription() {
			return this.subscriptionInfo.lastSubscription
		},
		nextInvoiceMessage() {
			const sub = this.lastSubscription || this.subscriptionInfo.currentSubscription
			// ^ Should always be the this.lastSubscription, but this prevents an error when we are mocking subs for cypress
			const planPeriod = sub.plan.frequency.toLowerCase()
			const upcomingInvoiceAmount = util.formatNumber(
				this.isTeam
					? sub.plan.price * this.totalUserCount + (sub.plan.basePlan ? sub.plan.basePlan.price : 0)
					: this.subscriptionInfo.upcomingInvoice
			)
			return `${this.formActive ? 'You' : 'Your primary card'} will be charged $${upcomingInvoiceAmount}${
				this.isTeam ? util.pluralize(this.totalUserCount, ' for {#} user{:s}') : ' '
			} and your ${planPeriod} subscription will resume.`
		},
	},
	created() {
		if ((this.pastDue || this.subscriptionExpired) && !this.subscriptionInfo.loaded) {
			subscriptionManager.getSubscriptions()
		}
	},
	methods: {
		close() {
			if (this.submittingCard || this.submittingRetry) return
			this.$emit('close')
		},
		onButtonClick() {
			if (this.isTeam && this.team.trialExpired) {
				this.close()
				this.$router.push('/team/upgrade/')
			} else if (this.subscriptionExpired) {
				if (this.formActive) {
					this.submittingRenewal = true
					eventBus.$emit('submit-card-info')
				} else {
					this.oneClickRenewSubscription()
				}
			}
		},
		onSubmit() {
			this.submittingCard = true
			this.clearError()
		},
		onSuccess() {
			this.chargeSucceeded = true
		},
		onEndSubmit() {
			this.submittingCard = false
			this.submittingRetry = false
			this.submittingRenewal = false
		},
		setError(errorMessage) {
			this.errorMessage = errorMessage
		},
		clearError() {
			this.errorMessage = ''
		},
		showForm() {
			this.clearError()
			this.formActive = true
		},
		renewSubscription(stripeToken, name, recaptchaToken) {
			subscriptionManager
				.resubscribe(stripeToken, name, recaptchaToken)
				.then(() => {
					this.chargeSucceeded = true
					if (this.isTeam) this.teamInfo.refreshTeams()
					subscriptionManager.getSubscriptions()
				})
				.catch(error => {
					if (error.message) this.setError(error.message)
					else this.setError(errorMessages.SERVER_ERROR_GENERAL)
				})
				.finally(this.onEndSubmit)
		},
		oneClickRenewSubscription() {
			this.clearError()
			this.submittingRenewal = true
			this.renewSubscription()
		},
		renewSubscriptionWithNewCard(result, name, recaptchaToken) {
			this.clearError()
			if (!result || result.error) {
				this.setError(result.error.message)
				this.onEndSubmit()
			} else {
				this.renewSubscription(result.token.id, name, recaptchaToken)
			}
		},
		retryPrimaryPaymentMethod() {
			this.submittingRetry = true
			this.clearError()
			subscriptionManager
				.retryLastChargeOnPrimaryCard(this.subscriptionInfo.currentSubscription.id)
				.then(response => {
					if (response.data.success) {
						subscriptionManager.getSubscriptions()
						if (this.isTeam) this.teamInfo.refreshTeams()
						else currentUserManager.getProfile()
						this.subscriptionSummary.pastDue = false
						this.onSuccess()
					} else {
						this.setError('Charge attempt failed.')
					}
				})
				.catch(error => {
					this.setError(error.message)
				})
				.finally(this.onEndSubmit)
		},
	},
}
</script>



<style scoped>
	.modal-overlay >>> .modal-content { padding: 0; }

		.expired-modal { padding: var(--box-padding); display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; }
			.expired-modal h2 { margin-bottom: var(--form-element-margin-small); }
			.expired-modal p { margin-bottom: var(--form-element-margin); }
				.expired-modal p .retry { color: var(--color-active); text-decoration: underline; cursor: pointer; }
				.expired-modal p .retry.retrying { text-decoration: none; }

			.loading >>> { height: 350px; display: flex; justify-content: center; align-items: center; }
				.loading >>> .section-loading { margin-top: 0; }

			.expired-modal >>> .payment-methods { margin-bottom: var(--form-element-margin); text-align: left; }
				.expired-modal >>> .payment-form { margin-bottom: 30px; }

				.expired-modal >>> .form-bottom-row { display: flex; }
				.expired-modal >>> .form-bottom-row .name-on-card { margin-right: 8px; flex-basis: 60%; }
				.expired-modal >>> .form-bottom-row .country { flex-basis: 40%; }
				.expired-modal >>> .button-row { display: flex; }
					.expired-modal >>> .payment-methods .button-row .button { margin-top: 0; }
				.expired-modal >>> .payment-methods .flair { display: none; }

				.expired-modal .form-message { margin-bottom: var(--form-element-margin); }

		.button-row { text-align: left; }

	.thanks { display: flex; flex-direction: column; align-items: center; }
	.icon-check { width: 6rem; margin: 2rem 0; }
</style>

<template>
	<section class="u--bg login-wrapper">
		<div v-if="isCommunity" class="brand">
			<img v-if="basicTeamInfo.logo" :src="basicTeamInfo.logo" alt="Momentum">
			<div v-else class="community-name" data-test="community-name">{{ basicTeamInfo.name }}</div>
		</div>

		<h1 v-else class="brand">
			<img src="../../img/logo-white.svg" alt="Momentum">
		</h1>

		<section class="modal-wrapper modal-wrapper-login">
			<transition name="move-fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</section>
		<section class="bottom-message">
			<p>Having trouble? See our <a class="login-link" href="http://momentumdash.help" target="_blank" @click="onHelpClick">Help Center</a></p>
		</section>
	</section>
</template>


<script>
import Analytics from '../../Analytics'

export default {
	name: 'AuthBase',
	store: ['basicTeamInfo'],
	computed: {
		isCommunity() {
			return this.basicTeamInfo.uuid
		},
	},
	methods: {
		onHelpClick() {
			Analytics.capture('help clicked', { feature: 'login', page: this.$route.name })
		},
	},
}
</script>

<style scoped>
	.brand { flex-direction: column; color: white; }
		.community-name { margin-top: 1rem; }
</style>

/* LIBRARIES */
import Vue from 'vue'
import Router from 'vue-router'

/* JS */
import store from '@/store'
import authManager from './managers/authManager'
import { navVisibilityStates, experiments } from '@/constants'
import StorageHelper from '@/storageHelper'
import util from './util'

/* COMPONENTS */

// General
const LinkExpired = () => import('@/components/common/LinkExpired')
const AccountDeleted = () => import('@/components/user/AccountDeleted')
const Help = () => import('@/components/user/Help')
const DeleteConfirmation = () => import('@/components/user/DeleteConfirmation')
const Launch = () => import('@/components/launch/Launch')
const Welcome = () => import('@/components/auth/Welcome')
const NotFound = () => import('@/components/common/404')
const AlreadySubscribed = () => import('@/components/common/AlreadySubscribed')
const TransactionalPage = () => import('@/components/common/TransactionalPage')
const OneTime = () => import('./components/auth/OneTime')

const SafariSetup = () => import('./components/safari/SafariSetup')

// Auth (not lazy loaded because it breaks the okta login redirect)
import AuthBase from '@/components/auth/AuthBase'
import Login from '@/components/auth/Login'
import Register from '@/components/auth/Register'
import Recover from '@/components/auth/Recover'
import TeamRedirect from '@/components/auth/TeamRedirect'

// Single Sign On
import SsoLogin from '@/components/sso/SsoLogin'
// OAuth
import OAuthLogin from '@/components/oauth/OAuthLogin'

import eventBus from '@/eventBus'
import subscriptionManager from './managers/subscriptionManager'
import teamManager from './managers/teamManager'

// Profile
const Profile = () => import(/* webpackChunkName: "profile" */ './components/user/Profile')
const ProfileDetails = () => import(/* webpackChunkName: "profile" */ './components/user/ProfileDetails')
const Emails = () => import(/* webpackChunkName: "profile" */ './components/user/Emails')
const Delete = () => import(/* webpackChunkName: "profile" */ './components/user/Delete')
const SetPassword = () => import(/* webpackChunkName: "profile" */ './components/user/SetPassword')

// Subscription
const Upgrade = () => import(/* webpackChunkName: "subscription" */ '@/components/subscription/Upgrade')
const Gifts = () => import(/* webpackChunkName: "subscription" */ '@/components/gifts/Gifts')
const Subscription = () => import(/* webpackChunkName: "subscription" */ '@/components/subscription/Subscription')
const Thanks = () => import(/* webpackChunkName: "subscription" */ '@/components/subscription/Thanks')
const TeamThanks = () => import(/* webpackChunkName: "subscription" */ '@/components/subscription/TeamThanks')

// Team Misc
const TeamHome = () => import('./components/team/Home')

// Team Settings
const TeamSettings = () => import(/* webpackChunkName: "team-settings" */ './components/team/teamSettings/TeamSettings')
const Branding = () => import(/* webpackChunkName: "team-settings" */ './components/team/teamSettings/Branding')
const ApiKeys = () => import(/* webpackChunkName: "team-settings" */ '@/components/team/teamSettings/ApiKeys')
const Webhooks = () => import(/* webpackChunkName: "team-settings" */ '@/components/team/teamSettings/Webhooks')
const TeamMembers = () =>
	import(/* webpackChunkName: "team-settings" */ '@/components/team/teamSettings/users/TeamMembers')

// Team Onboarding
const Onboarding = () => import(/* webpackChunkName: "onboarding" */ '@/components/onboarding/Onboarding')
const OnboardingFinished = () =>
	import(/* webpackChunkName: "onboarding" */ '@/components/onboarding/OnboardingFinished')

// Team Items
const MetaAdd = () => import('@/components/metaAdd/MetaAdd')
const Links = () => import('@/components/team/links/Links')
const Clocks = () => import('@/components/team/clocks/Clocks')
const Countdowns = () => import('@/components/team/countdowns/Countdowns')
const Metrics = () => import('@/components/team/metrics/Metrics')
const Quotes = () => import('@/components/team/quotes/Quotes')
const Focus = () => import('@/components/team/focus/Focus')
const Todo = () => import('@/components/team/todo/Todo')
const Topics = () => import('@/components/team/topics/Topics')
const Apps = () => import(/* webpackChunkName: "apps" */ '@/components/team/apps/Apps')
const CategorizeApps = () => import(/* webpackChunkName: "apps" */ '@/components/team/apps/CategorizeApps')
const Announcements = () => import('@/components/team/announcements/Announcements')

const accountTitle = '• Account',
	teamTitle = '• Team',
	communityTitle = '• Community'

const router = new Router({
	mode: 'history',

	routes: [
		{
			path: '',
			redirect: '/team',
		},
		{
			path: '/profile',
			component: Profile,
			meta: { bodyClass: 'profile', title: `Profile ${accountTitle}`, isProfile: true },
			children: [
				{
					path: '',
					name: 'info',
					component: ProfileDetails,
					meta: {
						sectionClass: 'profile-info',
						title: `Profile Details ${accountTitle}`,
						isProfile: true,
					},
				},
				{
					path: 'emails',
					name: 'emails',
					component: Emails,
					meta: {
						sectionClass: 'profile-emails',
						title: `Emails ${accountTitle}`,
						isProfile: true,
					},
				},
				{
					path: 'password',
					name: 'password',
					component: SetPassword,
					meta: {
						sectionClass: 'profile-password',
						title: `Change Password ${accountTitle}`,
						isProfile: true,
					},
				},
				{
					path: 'delete',
					name: 'delete',
					component: Delete,
					meta: {
						sectionClass: 'profile-delete',
						title: `Delete ${accountTitle}`,
						isProfile: true,
					},
				},
			],
		},
		{
			path: '/auth',
			name: 'auth',
			redirect: '/',
			component: AuthBase,
			children: [
				{
					path: '/login',
					name: 'login',
					component: Login,
					props: { forTeam: false },
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Login ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},
				{
					path: '/team-login',
					name: 'team-login',
					component: Login,
					props: { forTeam: true },
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Login ${teamTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},
				{
					path: '/team-redirect',
					name: 'team-redirect',
					component: TeamRedirect,
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Logging in ${teamTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},
				{
					path: '/recover',
					name: 'recover',
					component: Recover,
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Recover ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},
				{
					path: '/register',
					name: 'register',
					component: Register,
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Register ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},

				{
					path: '/community/:communityName',
					redirect: '/community/:communityName/register',
				},
				{
					path: '/community/:communityName/register',
					name: 'communityRegister',
					component: Register,
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Register ${communityTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
					beforeEnter(to, from, next) {
						teamManager
							.getBasicTeamInfo(to.params.communityName)
							.then(() => next())
							.catch(() => next('/register'))
					},
				},
				{
					path: '/community/:communityName/login',
					name: 'communityLogin',
					component: Login,
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Login ${communityTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
					beforeEnter(to, from, next) {
						teamManager
							.getBasicTeamInfo(to.params.communityName)
							.then(() => next())
							.catch(() => next('/login'))
					},
				},
				{
					path: '/community/:communityName/recover',
					name: 'communityRecover',
					component: Recover,
					meta: {
						anonymous: true,
						redirectIfAuthenticated: true,
						title: `Recover ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
					beforeEnter(to, from, next) {
						teamManager
							.getBasicTeamInfo(to.params.communityName)
							.then(() => next())
							.catch(() => next('/recover'))
					},
				},
				{
					path: '/welcome',
					name: 'welcome',
					component: Welcome,
					meta: {
						anonymous: true,
						title: `Welcome ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
					beforeEnter(to, from, next) {
						if (store.authInfo.loggedIn) {
							if (!to.query.email || to.query.email === store.userInfo.email) {
								next('/')
							} else {
								authManager.logout()
								next()
							}
						} else {
							next()
						}
					},
				},

				{
					path: '/logout',
					name: 'logout',
					beforeEnter(to, from, next) {
						authManager.logout()
						if (from.meta.anonymous && from.meta.nonTeam) {
							// this is so that logged in users will stay on anonymous and non-team pages if they hit logout
							document.title = from.meta.title
							next(from.path)
						} else {
							next('/login')
						}
					},
					meta: {
						anonymous: true,
						title: `Logout ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},

				{
					name: 'sso-login',
					path: '/sso/:sso_provider/login',
					component: SsoLogin,
					meta: {
						bodyClass: 'sso-login',
						anonymous: false,
						title: `Authenticate ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},

				{
					name: 'oauth-login',
					path: '/oauth/:oauth_client/login',
					component: OAuthLogin,
					meta: {
						bodyClass: 'oauth-login',
						anonymous: false,
						title: `Authenticate ${accountTitle}`,
						nav: navVisibilityStates.HIDDEN,
					},
				},
			],
		},
		{
			name: 'launch',
			path: '/launch',
			component: Launch,
			meta: {
				bodyClass: 'center launch',
				anonymous: true,
				title: `Launch Dashboard ${accountTitle}`,
			},
			beforeEnter(to, from, next) {
				// Temp fix: PWA partners should not access this
				if (store.userInfo.partnerId) next('/')
			},
		},
		{
			name: 'set-password',
			path: '/set-password',
			component: SetPassword,
			meta: {
				bodyClass: 'set-password',
				anonymous: true,
				title: `Set Password ${accountTitle}`,
			},
		},
		{
			name: 'link-expired',
			path: '/link-expired',
			component: LinkExpired,
			meta: {
				bodyClass: 'center link-expired',
				anonymous: true,
				title: `Link Expired ${accountTitle}`,
				nav: navVisibilityStates.EMPTY,
			},
		},
		{
			name: 'onetime',
			path: '/onetime',
			component: OneTime,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Logging you in... ${accountTitle}`,
				nav: navVisibilityStates.EMPTY,
			},
		},
		{
			// this is for AB testing, right now it is only being used for upgrade & resubscribe, but should be generalized into a AB-Test-Wrapper or something
			name: 'upgrade',
			path: '/upgrade',
			component: Upgrade,
			beforeEnter(to, from, next) {
				to.meta.prop = {
					nav: store.authInfo.loggedIn ? navVisibilityStates.VISIBLE : navVisibilityStates.EMPTY,
				}
				if (
					store.authInfo.loggedIn &&
					!(to.query.email && to.query.email !== store.userInfo.email) &&
					(store.userInfo.activeSubscription || store.userInfo.isAdmin || store.userInfo.isTeamMember)
				) {
					next('/already-subscribed')
				} else {
					to.meta.prop = {
						nav: store.authInfo.loggedIn ? navVisibilityStates.VISIBLE : navVisibilityStates.EMPTY,
					}
					next()
				}
			},
			props: route => route.meta.prop,
			meta: {
				bodyClass: 'upgrade',
				anonymous: true,
				title: `Upgrade ${accountTitle}`,
				nonTeam: true,
				isUpgrade: true /* temporary solution only used when subscribed team users go to /upgrade */,
			},
		},
		{
			name: 'upgrade-coupon',
			path: '/upgrade/:coupon',
			component: Upgrade,
			beforeEnter(to, from, next) {
				// Temp fix: PWA partners should not access this
				if (store.userInfo.partnerId) next('/')
				if (store.authInfo.loggedIn && (store.userInfo.activeSubscription || store.userInfo.isTeamMember)) {
					next('/already-subscribed')
				} else {
					to.meta.prop = {
						nav: store.authInfo.loggedIn ? navVisibilityStates.VISIBLE : navVisibilityStates.EMPTY,
					}
					next()
				}
			},
			props: route => route.meta.prop,
			meta: {
				bodyClass: 'upgrade',
				anonymous: true,
				title: `Upgrade ${accountTitle}`,
				nonTeam: true,
				isUpgrade: true /* temporary solution only used when subscribed team users go to /upgrade */,
			},
		},
		{
			// This route is navigated to from an email.
			name: 'delete-confirmation',
			path: '/confirm-hard-delete',
			component: DeleteConfirmation,
			meta: {
				bodyClass: 'center',
				nav: navVisibilityStates.EMPTY,
				anonymous: true,
				title: `Delete ${accountTitle}`,
				nonTeam: true,
			},
		},
		{
			name: 'team-upgrade',
			path: '/team/upgrade',
			component: Upgrade,
			beforeEnter(from, to, next) {
				if (store.teamInfo.activeTeam.subscriptionSummary.hasPaymentMethod) next('/team/settings/billing')
				else next()
			},
			meta: { bodyClass: 'upgrade', title: `Upgrade ${accountTitle}` },
		},
		{
			/*
				Backwards-compatibility for legacy account site, which had a payment-methods standalone page.
				We want people who click a legacy link in an old payment-failed email to be able to get redirected to the new page.
			*/
			path: '/payment-methods',
			redirect: 'subscription',
		},
		{
			name: 'resubscribe',
			path: '/resubscribe',
			component: Upgrade,
			beforeEnter(to, from, next) {
				authManager.logout()
				const queryParams = to.query
				if (queryParams.email && (queryParams.cid || window.localStorage.getItem('correlationId'))) {
					// sent from email link (could be first time landing or re-click email link)
					to.meta.bodyClass = 'upgrade'
					next()
				} else {
					next('/upgrade')
				}
			},
			props: { experimentType: experiments.resubscribeFlow },
			meta: {
				bodyClass: '' /* this gets set in the beforeEnter */,
				anonymous: true,
				title: `Resubscribe ${accountTitle}`,
				nonTeam: true,
				nav: navVisibilityStates.EMPTY,
			},
		},
		{
			name: 'subscription',
			path: '/subscription',
			component: Subscription,
			beforeEnter(to, from, next) {
				if (store.authInfo.loggedIn && !store.userInfo.activeSubscription) {
					next('/upgrade')
				} else {
					next()
				}
			},
			meta: {
				bodyClass: 'subscription',
				title: `Subscription ${accountTitle}`,
				nonTeam: true,
			},
		},
		{
			name: 'thanks',
			path: '/thanks',
			component: Thanks,
			beforeEnter(to, from, next) {
				to.meta.prop = {
					sentFrom: from.name,
					nav: store.authInfo.loggedIn ? navVisibilityStates.VISIBLE : navVisibilityStates.EMPTY,
				}
				next()
			},
			meta: {
				bodyClass: 'thank-you',
				anonymous: true,
				title: `Thanks ${accountTitle}`,
				nonTeam: true,
			},
			props: route => route.meta.prop,
		},
		{
			name: 'team-thanks',
			path: '/team/thanks',
			component: TeamThanks,
			beforeEnter(to, from, next) {
				to.meta.prop = { sentFrom: from.name }
				next()
			},
			meta: { bodyClass: 'thank-you', title: `Thanks ${teamTitle}` },
			props: route => route.meta.prop,
		},
		{
			name: 'gifts',
			path: '/gifts',
			component: Gifts,
			meta: { bodyClass: 'gifts', anonymous: true, title: `Gifts ${accountTitle}` },
		},
		{
			name: 'help',
			path: '/help',
			component: Help,
			meta: { bodyClass: 'help', anonymous: true, title: `Help ${accountTitle}` },
		},
		{
			name: 'account-deleted',
			path: '/account-deleted',
			component: AccountDeleted,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Account deleted ${accountTitle}`,
				nonTeam: true,
				nav: navVisibilityStates.EMPTY,
			},
		},
		{
			path: '/safari-setup',
			component: SafariSetup,
			meta: { title: `Safari Setup ${accountTitle}` },
		},
		{
			path: '/team',
			redirect: '/team/home',
		},
		{
			name: 'home',
			path: '/team/home',
			component: TeamHome,
			meta: { bodyClass: 'team', title: `Home ${teamTitle}` },
		},
		{
			name: 'Add',
			path: '/team/add',
			component: MetaAdd,
			meta: { bodyClass: 'meta-add', title: `Add ${teamTitle}` },
		},
		{
			path: '/team/settings',
			component: TeamSettings,
			meta: { bodyClass: 'team', title: `Team ${teamTitle}` },
			children: [
				{
					name: 'branding',
					path: '',
					component: Branding,
					meta: { sectionClass: 'branding', title: `Branding ${teamTitle}` },
				},
				{
					name: 'api-keys',
					path: 'api/keys',
					component: ApiKeys,
					meta: { sectionClass: 'api-keys', title: `API keys ${teamTitle}` },
				},
				{
					name: 'webhooks',
					path: 'api/webhooks',
					component: Webhooks,
					meta: { sectionClass: 'webhooks', title: `Webhooks ${teamTitle}` },
				},
				{
					name: 'billing',
					path: 'billing',
					component: Subscription,
					meta: {
						sectionClass: 'billing',
						title: `Billing ${teamTitle}`,
						isBilling: true,
					},
					beforeEnter(from, to, next) {
						const team = store.teamInfo.activeTeam
						if (team && !team.subscriptionSummary.hasPaymentMethod && !team.paymentWaived) {
							next('/team/upgrade')
						} else {
							next()
						}
					},
				},
			],
		},
		{
			name: 'announcements',
			path: '/team/announcements',
			component: Announcements,
			meta: { sectionClass: 'announcements', title: `Team Announcements ${teamTitle}` },
		},
		{
			name: 'teamMembers',
			path: '/team/members',
			component: TeamMembers,
			meta: { sectionClass: 'members', title: `Team Members ${teamTitle}` },
		},
		{
			name: 'metrics',
			path: '/team/metrics',
			component: Metrics,
			meta: { bodyClass: 'metrics', title: `Metrics ${teamTitle}` },
		},
		{
			name: 'links',
			path: '/team/links',
			component: Links,
			meta: { bodyClass: 'links', title: `Links ${teamTitle}` },
		},
		{
			name: 'countdowns',
			path: '/team/countdowns',
			component: Countdowns,
			meta: { bodyClass: 'countdowns', title: `Countdowns ${teamTitle}` },
		},
		{
			name: 'clocks',
			path: '/team/clocks',
			component: Clocks,
			meta: { bodyClass: 'clocks', title: `Clocks ${teamTitle}` },
		},
		{
			name: 'quotes',
			path: '/team/quotes',
			component: Quotes,
			meta: { bodyClass: 'quotes', title: `Quotes ${teamTitle}` },
		},
		{
			name: 'todo',
			path: '/team/todo',
			component: Todo,
			meta: { bodyClass: 'todo', title: `Todo ${teamTitle}` },
		},
		{
			name: 'focus',
			path: '/team/focus',
			component: Focus,
			meta: { bodyClass: 'focus', title: `Focus ${teamTitle}` },
		},
		{
			name: 'topics',
			path: '/team/topics',
			component: Topics,
			meta: { bodyClass: 'topics', title: `Topics ${teamTitle}` },
		},
		{
			name: 'apps',
			path: '/team/apps',
			component: Apps,
			meta: { bodyClass: 'apps', title: `Apps ${teamTitle}` },
		},
		{
			name: 'categorizeApps',
			path: '/team/apps/categorize',
			component: CategorizeApps,
			meta: { bodyClass: 'categorize-apps', title: `Categorize Apps ${teamTitle}` },
			beforeEnter(to, from, next) {
				if (store.teamInfo.activeTeam.disableFeatures) {
					if (from.name) eventBus.$emit('showExpiredModal')
					else next('/team/apps')
				} else {
					next()
				}
			},
		},
		{
			name: 'onboarding',
			path: '/onboarding',
			component: Onboarding,
			meta: {
				anonymous: true,
				bodyClass: 'onboarding',
				title: `Team Setup ${teamTitle}`,
				nav: navVisibilityStates.EMPTY,
				isOnboarding: true,
			},
		},
		{
			name: 'onboarding-finished',
			path: '/onboarding-finished',
			component: OnboardingFinished,
			meta: {
				bodyClass: 'onboarding-finished',
				title: `Go To Dashboard ${teamTitle}`,
				nav: navVisibilityStates.EMPTY,
			},
		},
		{
			name: 'password-reset',
			path: '/password-reset',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Password Reset Succeeded ${accountTitle}`,
				nav: navVisibilityStates.EMPTY,
			},
			props: { messageType: 'passwordResetSuccess' },
		},
		{
			name: 'verification-failed',
			path: '/verification-failed',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Verification Failed ${accountTitle}`,
				nav: navVisibilityStates.EMPTY,
			},
			props: { messageType: 'verificationFailed' },
		},
		{
			name: 'account-created',
			path: '/account-created',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Account Created ${accountTitle}`,
				nav: navVisibilityStates.EMPTY,
			},
			props: { messageType: 'accountCreated' },
		},
		{
			name: 'activationError',
			path: '/activation-error',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Error ${teamTitle}`,
				nav: navVisibilityStates.EMPTY,
				isErrorPage: true,
			},
			props: { messageType: 'activationError' },
		},
		{
			name: 'tokenExpiredError',
			path: '/token-expired',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Expired ${teamTitle}`,
				nav: navVisibilityStates.EMPTY,
				isErrorPage: true,
			},
			props: { messageType: 'tokenExpired' },
		},
		{
			name: 'alreadyConfirmedError',
			path: '/already-confirmed',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Confirmed ${teamTitle}`,
				nav: navVisibilityStates.EMPTY,
				isErrorPage: true,
			},
			props: { messageType: 'alreadyConfirmed' },
		},
		{
			name: 'badRequest',
			path: '/not-found',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Not found ${teamTitle}`,
				isErrorPage: true,
			},
			props: { messageType: 'notFound' },
		},
		{
			name: '404',
			path: '*',
			component: NotFound,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Not Found ${accountTitle}`,
				nav: navVisibilityStates.VISIBLE,
				isErrorPage: true,
			},
		},
		{
			name: 'launchExtensionError',
			path: '/launch-extension-error',
			component: TransactionalPage,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Error ${accountTitle}`,
				isErrorPage: true,
			},
			props: { messageType: 'launchExtensionError' },
			beforeEnter(to, from, next) {
				// Temp fix: PWA partners should not access this
				if (store.userInfo.partnerId) next('/')
			},
		},
		{
			name: 'alreadySubscribed',
			path: '/already-subscribed',
			component: AlreadySubscribed,
			meta: {
				bodyClass: 'center',
				anonymous: true,
				title: `Thanks for your support ${accountTitle}`,
				nav: navVisibilityStates.VISIBLE,
				isErrorPage: true,
			},
		},
	],
})

router.beforeEach(function (to, from, next) {
	// Store and strip any UTM-related parameters from the query string
	let updatedQuery = new StorageHelper(to.query)
		.storeInLocalStorage('cid', 'correlationId')
		.storeInSessionStorage('utm_source')
		.storeInSessionStorage('utm_medium')
		.storeInSessionStorage('utm_campaign')
		.storeInSessionStorage('utm_term')
		.storeInSessionStorage('utm_content')
		.storeInSessionStorage('session')
		.updatedQueryParams()
	// if the UTM parameters do exist, then remove them and return, so that we don't get a race condition in this .beforeEach()
	if (updatedQuery) {
		next({ path: to.path, query: updatedQuery })
		return
	}

	window.track = window.track || []
	window.track.push(to.fullPath)

	let isTeam = to.path && to.path.indexOf('/team/') > -1
	Vue.set(store, 'teamActive', isTeam)

	// GUARDS
	if (to.meta && !to.meta.anonymous && !store.authInfo.loggedIn) {
		// Redirect to login if route requires auth and user isn't authenticated
		next({
			path: '/login',
			query: {
				redirect: to.fullPath,
			},
		})
		return
	}
	if (to.meta.nonTeam && store.userInfo.isTeamMember && !to.meta.isUpgrade) {
		// Redirect team members off of routes that are not available for teams.
		next({ name: '404' })
		return
	}
	if (store.authInfo.loggedIn && isTeam && !store.userInfo.isAdmin) {
		// Don't let non-admins on any routes containing /team (isTeam check).
		next({ path: '/profile' })
		return
	}
	if (store.authInfo.loggedIn && to.meta.redirectIfAuthenticated) {
		// Redirect users off of anonymous only routes (like login, register, etc.)
		const redirect = util.getRelativeUrl(to.query.redirect)
		if (redirect) next(redirect)
		else next('/')
		return
	}

	// SET CLASSES TODO: refactor so this doesn't happen in the router as it is far too complicated. Add classes to component top level element instead
	if (to.meta && to.meta.bodyClass) {
		Vue.set(store.interfaceState, 'bodyClass', to.meta.bodyClass)
		Vue.set(store.interfaceState, 'sectionClass', '')
		document.title = to.meta.title
	} else if (to.meta && to.meta.sectionClass) {
		Vue.set(store.interfaceState, 'sectionClass', to.meta.sectionClass)
		Vue.set(
			store.interfaceState,
			'bodyClass',
			to.matched.length && to.matched[0] && to.matched[0].meta && to.matched[0].meta.bodyClass
				? to.matched[0].meta.bodyClass
				: ''
		)
		// Grab the bodyClass from the parent route ^
		document.title = to.meta.title
	} else {
		Vue.set(store.interfaceState, 'sectionClass', '')
		Vue.set(store.interfaceState, 'bodyClass', '')
		document.title = to.meta.title
	}
	// Get Plan info
	if ((!store.userInfo.isTeamMember || to.meta.isUpgrade) && !store.planInfo.loaded) {
		subscriptionManager.getPlans(to.params.coupon)
	}
	next()
})
export default router
